import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import {
  AuthLayout,
  SignUpForm,
  UpdatePasswordForm,
} from '@tymate/elise/components';
import { useAuth } from 'hooks';
import queryString from 'query-string';
import { acceptInvitation, updatePassword, getTerms } from 'api/auth';
import { history } from 'index';
import { useAppState } from 'hooks';
import logo from 'images/logo-full-white.svg';
import background from 'images/background-auth.png';
import front from 'images/front.jpg';
import { useMutation, useQuery } from 'react-query';

const Callback = ({ location }) => {
  const { notify } = useAppState();
  const { onUpdate, onReset } = useAuth();

  const params = queryString.parse(location.search);
  const invitationToken = params?.invitation_token;
  const resetPasswordToken = params?.reset_password_token;

  const { data: terms } = useQuery(['terms'], () => getTerms(), {
    enabled: Boolean(invitationToken),
  });

  const { mutateAsync: mutateInvitation } = useMutation(
    values => acceptInvitation(values),
    {
      onSuccess: ({ data }) => {
        onUpdate(data);
        history.push('/');
      },
    },
  );

  const { mutateAsync: mutatePassword } = useMutation(
    values => updatePassword(values),
    {
      onSuccess: ({ data }) => {
        onUpdate(data);
        notify('Mot de passe mis à jour.');
        history.push('/');
      },
      onError: () => {
        notify('Erreur lors de la mise à jour du mot de passe.');
      },
    },
  );

  const handleUpdatePassword = async values => {
    await mutatePassword({
      user: {
        password: values.password,
        resetPasswordToken,
      },
    });
  };

  const handleSubmitSignupForm = async values => {
    const gtuId = terms?.data?.filter(({ kind }) => kind === 'gtu')?.[0]?.id;
    const gtcId = terms?.data?.filter(({ kind }) => kind === 'gtc')?.[0]?.id;
    const user = {
      ...values,
      invitationToken: invitationToken,
      gtuId,
      gtcId,
    };

    await mutateInvitation({ user });
  };

  useEffect(() => {
    onReset();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthLayout
      backgroundImage={background}
      sideImage={
        location.pathname.indexOf('/callback/invitation') === 0 && front
      }
      logo={logo}
    >
      <Route
        path="/callback/reset_password"
        render={() => <UpdatePasswordForm onSubmit={handleUpdatePassword} />}
      />

      <Route
        path="/callback/invitations"
        render={props => (
          <SignUpForm
            app="franchise"
            onSubmit={handleSubmitSignupForm}
            terms={terms?.data ?? []}
            {...props}
          />
        )}
      />
    </AuthLayout>
  );
};

export default Callback;
