import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { List, ButtonReset } from '../ui';

const Wrapper = styled(List)`
  display: flex;
`;

const ItemButton = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1.25)};
  color: ${({ theme }) => theme.primary};
  transition: background-color 100ms ease, color 100ms ease;
  background-color: ${({ theme }) => theme.white};

  &:hover {
    background-color: ${({ theme }) => theme.primaryLighten};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &,
      &:hover {
        background-color: ${({ theme }) => theme.primaryLighten};
      }
    `}

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textDisabled};
    cursor: not-allowed;

    ${({ isActive }) =>
      isActive &&
      css`
        &,
        &:hover {
          background-color: ${({ theme }) => theme.primaryLighten};
          color: ${({ theme }) => theme.primary};
        }
      `}
  }
`;

const Item = styled.li`
  box-shadow: inset 0 0 0 1px #e0e0e0;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.primary};
  font-weight: 600;

  &:first-child {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }

  &:last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  + li {
    margin-left: -1px;
  }
`;

const SegmentedControl = ({
  options,
  onChange,
  multiple,
  values,
  label,
  disabled,
}) => {
  const [selectedValues, setSelectedValues] = useState(values || []);

  const handleToggleValue = value => {
    if (multiple) {
      const nextValues =
        selectedValues.indexOf(value) > -1
          ? selectedValues.filter(v => v !== value)
          : [...selectedValues, value];

      if (Boolean(onChange)) {
        onChange(nextValues);
      }

      setSelectedValues(nextValues);
    } else {
      if (Boolean(onChange)) {
        onChange(value);
      }

      setSelectedValues([value]);
    }
  };

  useEffect(() => {
    if (!multiple) {
      setSelectedValues([get(options, [0, 'value'])]);
    }
  }, [multiple, options]);

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  return (
    <>
      {Boolean(label) && (
        <strong style={{ paddingBottom: '1rem' }}>{label}</strong>
      )}
      <Wrapper>
        {options.map(({ label, value }, index) => (
          <Item key={index}>
            <ItemButton
              isActive={(selectedValues || []).indexOf(value) > -1}
              onClick={() => handleToggleValue(value)}
              type="button"
              disabled={disabled}
            >
              {label}
            </ItemButton>
          </Item>
        ))}
      </Wrapper>
    </>
  );
};

SegmentedControl.defaultProps = { multiple: false, values: [] };

export default SegmentedControl;
