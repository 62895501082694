import React, { useState } from 'react';
import Tooltip from '@tymate/elise/components/Tooltip';
import LinesEllipsis from 'react-lines-ellipsis';

const OneLineTruncate = ({
  text,
  tooltipVariant = 'medium',
  isTop = false,
}) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const handleReflow = ({ clamped }) => {
    if (clamped && !isTruncated) {
      setIsTruncated(true);
    }
  };

  return (
    <Tooltip
      disabled={!isTruncated}
      variant={tooltipVariant}
      tip={text}
      top={isTop}
    >
      <LinesEllipsis
        text={text || ''}
        trimRight
        basedOn="letters"
        onReflow={handleReflow}
      />
    </Tooltip>
  );
};

export default OneLineTruncate;
