import React, { useState } from 'react';
import { useDebounce, useDeepCompareEffect, useLocation } from 'react-use';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { pickBy, omit } from 'lodash';
import Search from '@tymate/elise/components/Search';

const LocationAwareSearch = ({
  placeholder,
  variant,
  omitSearchParams = [],
  searchParamName = 'search',
  isSelectAndSearch = false,
  isRedirectScan = false,
  redirectUrl,
  size,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { search, pathname } = location;
  const query = queryString.parse(search);
  const [value, setValue] = useState(query[searchParamName] || '');
  const [debouncedValue, setDebouncedValue] = useState(
    query[searchParamName] || '',
  );

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    500,
    [value],
  );

  useDeepCompareEffect(() => {
    const newSearch = queryString.stringify(
      omit(
        pickBy({
          ...omit(query, omitSearchParams),
          [searchParamName]: debouncedValue,
        }),
        ['page'],
      ),
    );

    history.push(`${pathname}${newSearch ? '?' + newSearch : ''}`);
  }, [{ debouncedValue }]);

  useDeepCompareEffect(() => {
    if (searchParamName !== value) {
      setValue('');
    }
  }, [{ searchParamName }]);

  return (
    <Search
      value={value}
      placeholder={placeholder}
      onChange={e => setValue(e.target.value)}
      onClear={() => setValue('')}
      variant={variant}
      isSelectAndSearch={isSelectAndSearch}
      size={size}
      isRedirectScan={isRedirectScan}
      redirectUrl={redirectUrl}
    />
  );
};

export default LocationAwareSearch;
