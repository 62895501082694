import React, { useContext } from 'react';
import {
  HeaderWrapper,
  HeaderLogo,
  HeaderLogoLink,
  MainNav,
  MainNavItem,
  MainNavItemLink,
} from '@tymate/elise/ui/layout';
import UserDropdown from '@tymate/elise/components/UserDropdown';
import logo from '../images/logo.png';
import { AuthContext } from 'contexts';
import { useLocation } from 'react-use';
import PricingHeader from './Orders/Pricing/PricingHeader';

const Header = () => {
  const { user, onReset } = useContext(AuthContext);
  const location = useLocation();

  const isPricingHeader = location.pathname.includes('/tarification');

  return (
    <HeaderWrapper>
      {isPricingHeader ? (
        <PricingHeader />
      ) : (
        <>
          <HeaderLogoLink to="/">
            <HeaderLogo src={logo} />
          </HeaderLogoLink>
          <MainNav>
            <MainNavItem>
              <MainNavItemLink to="/points-de-collecte">
                Clients
              </MainNavItemLink>
            </MainNavItem>

            <MainNavItem>
              <MainNavItemLink to="/commandes">Commandes</MainNavItemLink>
            </MainNavItem>

            <MainNavItem>
              <MainNavItemLink to="/ordres-de-mission">
                Ordres de mission
              </MainNavItemLink>
            </MainNavItem>

            <MainNavItem>
              <MainNavItemLink to="/facturation">Facturation</MainNavItemLink>
            </MainNavItem>
          </MainNav>

          <UserDropdown user={user} onReset={onReset} app="franchise" />
        </>
      )}
    </HeaderWrapper>
  );
};

export default Header;
