import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { theme } from '@tymate/elise/ui';
import App from './App';
import Auth from './containers/Auth';
import ForgottenPassword from './containers/ForgottenPassword';
import Callback from './containers/Callback';
import AuthProvider from './containers/AuthProvider';
import AppProvider from '@tymate/elise/providers/AppProvider';
import { GlobalStyles } from '@tymate/elise/ui';
import * as serviceWorker from './serviceWorker';
import { MargaretProvider } from '@tymate/margaret';
import 'sanitize.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SENTRY_DSN, SENTRY_ENVIRONMENT, API_BASE } from './constants';
import { initSentry } from '@tymate/elise/utils/sentry';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20,
    },
  },
});

export const history = createBrowserHistory();

initSentry(SENTRY_DSN, SENTRY_ENVIRONMENT, API_BASE, history, 'elise-pro');

ReactDOM.render(
  <Suspense fallback={<div />}>
    <MargaretProvider theme={theme}>
      <Router history={history}>
        <QueryClientProvider client={client}>
          <AppProvider>
            <GlobalStyles />
            <AuthProvider>
              <Switch>
                <Route path="/connexion" component={Auth} />
                <Route path="/callback" component={Callback} />
                <Route
                  path="/mot-de-passe-oublie"
                  component={ForgottenPassword}
                />
                <Route component={App} />
              </Switch>
            </AuthProvider>
          </AppProvider>
        </QueryClientProvider>
      </Router>
    </MargaretProvider>
  </Suspense>,
  document.getElementById('root'),
);

serviceWorker.unregister();
