import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  AuthLayout,
  ForgottenPasswordForm,
} from '@tymate/elise/components';
import { postForgotPassword } from 'api/auth';
import background from 'images/background-auth.png';
import { Buttons } from '@tymate/elise/ui';
import circleChecked from 'images/circleChecked.svg';
import logo from 'images/logo-full-white.svg';
import { ERRORS } from '@tymate/elise/utils';

const WrapperStepTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 60px;
    width: 60px;
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing()};
  }

  h2 {
    color: ${({ theme }) => theme.text};
    font-size: 1.5rem;
    line-height: 2;
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.textLight};
    line-height: 1.5;
  }
`;

const ForgottenPassword = () => {
  const [step, setStep] = useState(1);

  const handleSubmitForgottenPasswordForm = async (values, { setStatus }) => {
    try {
      await postForgotPassword({ user: values });
      setStep(2);
    } catch (err) {
      setStatus(ERRORS.EMAIL_NOT_FOUND);
    }
  };

  return (
    <AuthLayout backgroundImage={background} logo={logo}>
      {step === 1 && (
        <ForgottenPasswordForm onSubmit={handleSubmitForgottenPasswordForm} />
      )}
      {step === 2 && (
        <div>
          <WrapperStepTwo>
            <img src={circleChecked} alt="" />
            <h2>Email envoyé avec succès !</h2>
            <p>
              Si cette adresse email existe dans notre base de données, vous
              allez recevoir sous peu un email contenant un lien pour générer un
              nouveau mot de passe.
            </p>
          </WrapperStepTwo>
          <Buttons>
            <Button to="/connexion" variant="primary" size="full">
              Retour à l'écran de connexion
            </Button>
          </Buttons>
        </div>
      )}
    </AuthLayout>
  );
};

export default ForgottenPassword;
