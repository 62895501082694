import React, { useContext, lazy, Suspense } from 'react';
import { Page, Content } from '@tymate/elise/ui/layout';
import { AuthContext } from './contexts';
import Header from './components/Header';
import { Route, Switch, Redirect } from 'react-router-dom';
import useLocationBlocker from '@tymate/elise/hooks/useLocationBlocker';

const Franchise = lazy(() => import('./containers/Franchise'));
const Profile = lazy(() => import('./containers/Profile'));
const CollectionPoints = lazy(() => import('./containers/CollectionPoints'));
const Orders = lazy(() => import('./containers/Orders'));
const WorkOrders = lazy(() => import('containers/WorkOrders'));
const Invoicings = lazy(() => import('containers/Invoicings'));
const NotFound = lazy(() => import('./containers/NotFound'));

const App = () => {
  useLocationBlocker();
  const { accessToken } = useContext(AuthContext);

  if (!Boolean(accessToken)) {
    return <Redirect to="/connexion" />;
  }

  return (
    <Page>
      <Header />

      <Content>
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/points-de-collecte" component={CollectionPoints} />
            <Route path="/ordres-de-mission" component={WorkOrders} />
            <Route path="/commandes" component={Orders} />
            <Route path="/profil" component={Profile} />
            <Route path="/ma-franchise" component={Franchise} />
            <Route path="/facturation" component={Invoicings} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/points-de-collecte" />
          </Switch>
        </Suspense>
      </Content>
    </Page>
  );
};

export default App;
