import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { PopoverItem, PopoverItemButton, PopoverMenu } from '../ui/popover';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { get, find } from 'lodash';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import { Input } from '../ui/forms';
import { Dropdown, Tooltip } from '@tymate/elise/components';
import Truncate from 'react-truncate';
import { Stack } from '@tymate/margaret';

const Label = styled.label`
  display: block;
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: inherit;

  ${({ isField }) =>
    isField &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(0.25)};
      font-size: 14px;
      color: ${({ theme }) => theme.textLighten};
    `}
`;

const SubLabel = styled(Label)`
  color: ${({ theme }) => theme.textLighten};
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separator};
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(1.5)};
  width: 100%;

  ${({ variant, theme }) =>
    variant === 'input' &&
    css`
      background-color: #fff;
      padding: ${theme.spacing(0.5)} ${theme.spacing(0.75)};
      border-radius: 8px;
      color: inherit;
      font-size: 1rem;
      width: 100%;

      &:not([disabled]):focus,
      &:not([disabled]):active {
        border-color: ${({ theme }) => theme.primaryLight};
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
        color: inherit;
      }

      &:disabled {
        background-color: #eeeeee;
        cursor: not-allowed;
      }

      ${({ hasError, theme }) =>
        hasError &&
        css`
          &,
          &:hover,
          &:active {
            border: 1px solid ${theme.error};
          }
        `}
    `}

  ${({ hasFixedWidth, size }) =>
    hasFixedWidth &&
    css`
      width: ${size === 'auto' ? 300 : size}px;
    `}

  ${({ disabled, theme }) =>
    Boolean(disabled) &&
    css`
      background-color: ${theme.disabled};
      color: #6d6d6d;
    `}

  ${({ isBare }) =>
    isBare &&
    css`
      padding: 0;
    `}

  ${({ isSelectAndSearch }) =>
    isSelectAndSearch &&
    css`
      border-radius: 8px 0px 0px 8px;
      background-color: #00000008;
      width: 200px;
    `}

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const Select = ({
  label,
  placeholder,
  onChange,
  defaultValue,
  options,
  omitOptions = [],
  disabled,
  variant,
  wrapperStyle,
  value,
  hasFixedWidth,
  isSearchable,
  onUpdateSearch,
  size = 'auto',
  hasError,
  isSelectAndSearch,
  showOptionalLabel = false,
  shouldShowDash,
  hasCompactDropList = false,
  initialValueLabel,
  children,
  name,
  isField,
  selectedOptionLabel,
  top,
}) => {
  const searchInputRef = useRef();
  const dropdownRef = useRef();
  const valueLabel = get(
    find(options, opt => opt.value === defaultValue),
    'label',
  );

  const currentValueLabel =
    selectedOptionLabel ??
    find(options, opt => opt.value === value)?.label ??
    initialValueLabel;

  const initialValue = {
    value: defaultValue,
    label: valueLabel || initialValueLabel,
  };
  const omitedOptions = options.filter(
    element => !omitOptions.includes(element.value),
  );
  const [selectedValue, setSelectedValue] = useState(
    Boolean(defaultValue) ? initialValue : null,
  );
  const [search, setSearch] = useState('');
  const [shouldShowSearch, setShouldShowSearch] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  useDebounce(
    () => {
      if (!isSearchable) {
        return;
      }

      onUpdateSearch(search);
    },
    100,
    [search],
  );

  useDeepCompareEffect(() => {
    const selectedOption = find(options, option => option.value === value);

    if (!selectedOption) {
      return setSelectedValue(null);
    }
  }, [{ options, value }]);

  useDeepCompareEffect(() => {
    if (!shouldShowSearch) {
      setSearch('');
      return;
    }

    searchInputRef.current.focus();
  }, [{ shouldShowSearch }]);

  useDeepCompareEffect(() => {
    if (!selectedValue || !onChange) {
      return;
    }

    onChange(selectedValue);

    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ selectedValue }]);

  return (
    <>
      {label && (
        <Stack alignY="center" alignX="space-between">
          <Label isField={isField}>{label}</Label>
          {Boolean(showOptionalLabel) && <SubLabel>{'(optionnel)'}</SubLabel>}
        </Stack>
      )}

      <Dropdown
        disabled={disabled}
        ref={dropdownRef}
        wrapperStyle={wrapperStyle}
        shouldShowDash={shouldShowDash}
        onToggle={isOpen => setShouldShowSearch(isSearchable && isOpen)}
        shouldCloseDropdownOnTriggerClick
        trigger={
          <Trigger
            disabled={disabled}
            variant={variant}
            hasFixedWidth={hasFixedWidth}
            isBare={shouldShowSearch}
            hasError={hasError}
            size={size}
            isSelectAndSearch={isSelectAndSearch}
          >
            {shouldShowDash && '-'}
            {shouldShowSearch ? (
              <Input
                name={name}
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyUp={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                  }
                }}
                isBare
                ref={searchInputRef}
              />
            ) : (
              <>
                <Tooltip
                  disabled={!isTruncated}
                  tip={currentValueLabel || selectedValue?.label || placeholder}
                >
                  <div style={{ width: '100%' }}>
                    <Truncate onTruncate={value => setIsTruncated(value)}>
                      {currentValueLabel || selectedValue?.label || placeholder}
                    </Truncate>
                  </div>
                </Tooltip>
                <MdKeyboardArrowDown size={24} />
              </>
            )}
          </Trigger>
        }
      >
        <PopoverMenu
          size={size}
          isACompactDropList={hasCompactDropList}
          top={top}
        >
          {omitedOptions.map((option, index) => (
            <PopoverItem key={index}>
              <PopoverItemButton
                type="button"
                onClick={() => setSelectedValue(option)}
                isActive={option?.value === selectedValue?.value}
              >
                {option?.label}
              </PopoverItemButton>
            </PopoverItem>
          ))}
          {children}
        </PopoverMenu>
      </Dropdown>
    </>
  );
};

Select.defaultProps = {
  placeholder: '',
  options: [],
  omitOptions: [],
  onUpdateSearch: () => {},
};

export default Select;
