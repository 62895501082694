import React from 'react';
import { HeaderLogo, HeaderLogoLink } from '@tymate/elise/ui/layout';
import logo from 'images/logo.png';
import PricingBreadcrumb from './PricingBreadcrumb';

const PricingHeader = () => {
  return (
    <>
      <PricingBreadcrumb />
      <HeaderLogoLink
        to="/"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <HeaderLogo src={logo} />
      </HeaderLogoLink>
    </>
  );
};

export default PricingHeader;
