import * as React from 'react';

const IcArrowLeft = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="none"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m10 5-7 7m0 0 7 7m-7-7h18"
    />
  </svg>
);
export default IcArrowLeft;
